<template>
    <v-card flat>
        <v-card-text class="pa-0">
            <v-tabs height="25" v-model="tabView" class="mt-10" ref="tabs" show-arrows>
                <v-tabs-slider></v-tabs-slider>
                <v-tab dark width="50">
                    <span class="caption">query history</span>
                </v-tab>
                <v-tab-item class="mt-5">
                    <QueryHistory
                        v-if="snapshotQueries.length"
                        :snapshotQueries="snapshotQueries"
                        @queryResultsSuccess="moveToQueryPreview()"
                        @queryFilesSuccess="moveToQueryFiles()"></QueryHistory>
                    <v-alert v-else prominent type="info" text>Query history is empty.</v-alert>
                </v-tab-item>
                <v-tab dark height="10" :disabled="disableQueryPreview">
                    <span class="caption">query preview</span>
                </v-tab>
                <v-tab-item class="mt-5">
                    <QueryTablePreview
                        @querySubmitSuccess="tabView = tabOptions.QUERY_HISTORY"
                        @queryFilesSaveSuccess="tabView = tabOptions.QUERY_HISTORY"></QueryTablePreview>
                </v-tab-item>
                <v-tab dark height="10" :disabled="disableQueryFiles">
                    <span class="caption">Query Files</span>
                </v-tab>
                <v-tab-item class="mt-3">
                    <QueryFiles></QueryFiles>
                </v-tab-item>
            </v-tabs>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import 'github-markdown-css/github-markdown.css'
import { enumsData } from '@/mixins/enums'
const QueryHistory = () => import('../components/TheSnapshotQueryHistoryTable')
const QueryTablePreview = () => import('../components/TheSnapshotQueryPreviewTable')
const QueryFiles = () => import('../components/TheSnapshotQueryFiles')
export default {
    mixins: [enumsData],
    components: {
        QueryHistory,
        QueryTablePreview,
        QueryFiles
    },
    data() {
        return {
            tabView: 0,
            sqlQuery: 'SELECT *\nFROM\nWHERE\nLIMIT 10',
            tabOptions: {
                QUERY_FILES: 2,
                QUERY_PREVIEW: 1,
                QUERY_HISTORY: 0
            },
            queryUpdater: null,
            queryTableColumns: [],
            disableQueryPreview: true,
            disableQueryFiles: true,
            tableActivation: null,
            sqlDialog: false,
            connectionMenu: false,
            tableStatusOptions: {
                ACTIVATED_TABLES: 'ACTIVE',
                ACTIVATING_TABLES: 'ACTIVATING',
                INACTIVE_TABLES: 'INACTIVE',
                EMPTY_TABLES: 'EMPTY'
            }
        }
    },
    computed: {
        ...mapState('snapshotStore', ['currentTables', 'currentColumns', 'tablesFetching', 'snapshotQueries', 'latestQuery', 'fullQueryData', 'tablesPath'])
    },
    methods: {
        moveToQueryPreview: function () {
            this.disableQueryPreview = false
            this.tabView = this.tabOptions.QUERY_PREVIEW
        },
        moveToQueryFiles: function () {
            this.disableQueryFiles = false
            this.tabView = this.tabOptions.QUERY_FILES
        }
    },
    watch: {
        'latestQuery.status_name': function (nextValue) {
            if (nextValue === 'COMPLETED' && this.tabView === this.tabOptions.QUERY_HISTORY) {
                if (this.latestQuery.mode_name === 'PREVIEW') {
                    this.$axios
                        .get(`/queries/${this.latestQuery.qid}/results`)
                        .then(response => {
                            if (response.data.length) {
                                this.$store.dispatch('snapshotStore/setQueryPreviewData', {
                                    data: response.data,
                                    sql: this.latestQuery.command,
                                    qid: this.latestQuery.qid
                                })
                            } else {
                                this.$store.dispatch('snapshotStore/setQueryPreviewData', {
                                    data: [],
                                    sql: this.latestQuery.command,
                                    qid: this.latestQuery.qid
                                })
                            }
                        })
                        .finally(() => {
                            this.disableQueryPreview = false
                            this.$store.dispatch('showSnackBar', {
                                snackBarText: 'Results are ready!',
                                snackBarTimeout: 5000,
                                snackBarIcon: 'check_circle'
                            })
                            this.tabView = this.tabOptions.QUERY_PREVIEW
                        })
                } else if (this.latestQuery.mode_name === 'FULL_WITH_SAVE') {
                    this.$store.dispatch('snapshotStore/fetchFullQueryData', this.latestQuery.qid).finally(() => {
                        if (this.$store.state.snapshotStore.fullQueryData) {
                            this.disableQueryFiles = false
                            this.tabView = this.tabOptions.QUERY_FILES
                        }
                    })
                }
            }
        },
        tabView: function (to) {
            setTimeout(() => {
                this.$refs.tabs.onResize()
            }, 300)
        },
        snapshotQueries: function (nextVal, preVal) {
            if (nextVal.length && (!preVal || preVal.length !== nextVal.length)) {
                this.tabView = this.tabOptions.QUERY_HISTORY
            }
        }
    }
}
</script>
